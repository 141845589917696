body {
    background: #23353d;
    color: #999999;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.hidden {
    display: none;
}

@keyframes blinking {
    50% {
        opacity: 10%;
    }
}

@keyframes timer {
    to {
        opacity: 0;
    }
}

.airplanes {
    list-style: none;
    padding: 0;
}

air-plane {
    display: list-item;
    background: #325562;
    margin: 1rem;
    border-radius: 1rem;
    opacity: 1;
    transition: background-color 1s 5s ease, color 1s 5s ease;
    animation: timer 1s ease 600s;
}

air-plane::part(map) {
    filter: url("assets/filter.svg#x-rays") brightness(1) hue-rotate(311deg);
}

.airplane-transition--new {
    background: #ee505a;
    color: #222222;
    animation: blinking 3s ease;
}

.airplane-transition--update {
    background: #C7A424;
    color: #222222;
    animation: blinking 0.5s ease;
}
