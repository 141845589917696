body {
  color: #999;
  background: #23353d;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

.hidden {
  display: none;
}

@keyframes blinking {
  50% {
    opacity: .1;
  }
}

@keyframes timer {
  to {
    opacity: 0;
  }
}

.airplanes {
  padding: 0;
  list-style: none;
}

air-plane {
  opacity: 1;
  background: #325562;
  border-radius: 1rem;
  margin: 1rem;
  transition: background-color 1s 5s, color 1s 5s;
  animation: 1s 600s timer;
  display: list-item;
}

air-plane::part(map) {
  filter: url("filter.10b98988.svg#x-rays") brightness() hue-rotate(311deg);
}

.airplane-transition--new {
  color: #222;
  background: #ee505a;
  animation: 3s blinking;
}

.airplane-transition--update {
  color: #222;
  background: #c7a424;
  animation: .5s blinking;
}

/*# sourceMappingURL=index.989a894a.css.map */
